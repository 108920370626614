<template>

  <KeyEmail
    :left="left"
    :right="right"
    :title="variable" 
    :mandatory="mandatory"
    :placeholder="placeholder" 
    :disabled="disabled" 
    :defaultValue="defaultValue"
    v-on:value="setValue" />

</template>

<script>

import { mapGetters } from 'vuex'
import Catelog from '@/domain/session/CanadianEnglish.js';
import KeyEmail from '@/components/input/KeyEmail.vue';

export default {
  name: 'email-row',
  components: {
    KeyEmail,
  },
  props: {
    variable:    { type: String, default : "unknown_var" },
    placeholder: { type: String, default : "" },
    styling:    { type: String, default : "" },
    disabled:   { type: Boolean, default: false },
    left:       { type: Number, default : 0 },
    right:      { type: Number, default : 0 },
    mandatory:  { type: Boolean, default: false },
    defaultValue:  { type: String, default : "" },
  },
  data() {
    return {
      catelog: Catelog.KEYS,
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
  },
  methods: {
    setValue: function(kvp) {
      this.$emit('value', kvp)
    },
  },
}
</script>