<template>
  <div class="container">
    
    <TitleRow :label="catelog.SETTINGS.INSPECTION_EMAILTO_TITLE" />

    <LabelRow :label="catelog.SETTINGS.INSPECTION_EMAILTO_HELP" />
  
    <ErrorRow :error="error" />
    <SuccessRow :message="message" />
    
    <SpacerRow />
    
    <EmailRow 
      variable="inspectionsTo"
      placeholder="Email inspections to:"
      :disabled="isSaving"
      :defaultValue="data()['inspectionsTo']"
      v-on:value="setValue" />
    
    <SaveRow :disabled="isSaving" v-on:value="save" />
    
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';

import Catelog from '@/domain/session/CanadianEnglish.js';

import SaveRow from '@/components/row/SaveRow.vue'
import EmailRow from '@/components/row/EmailRow.vue'
import SpacerRow from '@/components/row/SpacerRow.vue'
import ErrorRow from '@/components/row/ErrorRow.vue'
import LabelRow from '@/components/row/LabelRow.vue'
import TitleRow from '@/components/row/TitleRow.vue'
import SuccessRow from '@/components/row/SuccessRow.vue'

export default {
  name: 'email-inspections-to-setting',
  components: {
    ErrorRow,
    SuccessRow,
    TitleRow,
    LabelRow,
    SpacerRow,
    EmailRow,
    SaveRow,
  },
  data() {
    return {
      isSaving: false,
      catelog: Catelog.KEYS,
      kvps: {},
      error: null,
      message: null,
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                 ]),
  },
  mounted: function () {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN })
    }
  },
  methods: {
    data: function() {
      var company = this.domain.session().company();
      if (company) {
        return company.data;
      }
      return {};
    },
    setValue: function(kvp) {
      ContentUtils.set(this.kvps, kvp, this.domain.logger());
    },
    save: function() {
      this.domain.logger().info("Saving!");
    },
  },
}

</script>