<template> 
  <input 
    v-on:click="pressed"
    class="rc-font-medium w3-button"
    :class="showSelected"
    type=button
    :disabled="disabled" 
    :value="label" /> 
</template>

<script>
export default {
  name: 'back-button',
  components: {
  },
  props: {
    disabled: { type: Boolean,  default: false  },
    label:    { type: String,   default: 'Back' },
    selected: { type: Boolean,  default: false  },
  },
  data() {
    return {
    };
  },
  computed: {
    showSelected: function() {
      return this.selected ? 'rc-background-selected' : '';
    },
  },
  methods: {
    pressed: function() {
      this.$emit('click');
    },
  },
}
</script>
