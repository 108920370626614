<template>
  <div class="container">
    <div class="row">
      
      <div class="rc-box-col-3">
        <div class="container">
          
          <TitleRow 
            :label="catelog.SETTINGS.MENU_TITLE" 
            styling="rc-text-centre" />
        
          <div class="row">
            <div class="rc-box-col-3"> </div>
            <div class="rc-box-col-9 rc-text-center">
              <Button 
                :selected="showingAddress()"
                :label="domain.lang().get(catelog.SETTINGS.ADDRESS_TITLE)" 
                v-on:click="showAddress()" />
            </div>
            <div class="rc-box-col-3"> </div>
          </div>
          
          <div class="row">
            <div class="rc-box-col-3"> </div>
            <div class="rc-box-col-9 rc-text-center"> 
              <Button
                :selected="showingInspectionTo()"
                :label="domain.lang().get(catelog.SETTINGS.INSPECTION_EMAILTO_TITLE)" 
                v-on:click="showInspectionTo()" />
            </div>
            <div class="rc-box-col-3"> </div>
          </div>
          
          <div class="row">
            <div class="rc-box-col-3"> </div>
            <div class="rc-box-col-9 rc-text-center">
              <Button
                :selected="showingInvoiceTo()"
                :label="domain.lang().get(catelog.SETTINGS.INVOICE_EMAILTO_TITLE)" 
                v-on:click="showInvoiceTo()" />
            </div>
            <div class="rc-box-col-3"> </div>
          </div>
          
        </div>
      </div>
      
      <div class="rc-box-col-9">
        <InvoiceToSetting v-if="showingInvoiceTo()"/>
        <InspectionToSetting v-if="showingInspectionTo()"/>
      </div>
      <div class="rc-box-col-3"></div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import ConstUtils from '@/utils/ConstUtils.js';
import Catelog from '@/domain/session/CanadianEnglish.js';
import InvoiceToSetting from './EmailInvoiceToSetting.vue';
import InspectionToSetting from './EmailInspectionToSetting.vue';
import Button from '@/components/button/WhiteButton.vue';

import TitleRow from '@/components/row/TitleRow.vue'

export default {
  name: 'settings',
  components: {
    InvoiceToSetting,
    InspectionToSetting,
    Button,
    TitleRow,
  },
  data() {
    return {
      isSaving: false,
      catelog: Catelog.KEYS,
      params: { 
        address: 'address',
        invoiceTo:'invoiceTo', 
        inspectionTo:'inspectionTo', 
      },
      setting: 'address',
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                 ]),
  },
  mounted: function () {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN })
    }
  },
  methods: {
    showInvoiceTo: function() {
      this.setting = this.params.invoiceTo;
    },
    showingInvoiceTo: function() {
      return this.setting === this.params.invoiceTo;
    },
    showingAddress: function() {
      return this.setting === this.params.address;
    },
    showAddress: function() {
      this.setting = this.params.address;
    },
    showInspectionTo: function() {
      this.setting = this.params.inspectionTo;
    },
    showingInspectionTo: function() {
      return this.setting === this.params.inspectionTo;
    },

  },
}

</script>